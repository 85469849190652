[
  {
    "name": "rebus",
    "ownerAddress": "rebusvaloper183hv37en2dayslgf03zfr57crtjrchuazwm9h9"
  },
  {
    "name": "stride",
    "ownerAddress": "stridevaloper1ehmnl9jdf2hnj78va888gtpz9e3d4g4ll3wthh",
    "authzSupport": false
  },
  {
    "name": "jackal",
    "ownerAddress": "jklvaloper1qhm6hucmshaz6s3mdyl8jje9ryk7t5uxgxy6w8"
  },
  {
    "name": "acrechain",
    "ownerAddress": "acrevaloper10uc3h2348v9dxa7evkjhep8xxtsd8f7de3xg5t"
  },
  {
    "name": "empowerchain",
    "ownerAddress": "empowervaloper1786wmwws6avkrkx9nwv3az6g2j9ha953ydusdv",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "uptick",
    "ownerAddress": "uptickvaloper12tmn00ne92n5nljf3t5apyy2jtkfjfetdhvgca"
  }
]
